import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageSwiper, { ImageSwiperProps } from ".";
import Button from "../Button";
import Modal from "../Modal";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";

type ImageSwiperModalProps = {
    open: boolean;
    onClose?: () => void;
} & ImageSwiperProps;
const ImageSwiperModal = ({ open, onClose, images, useOldCloudinary = false }: ImageSwiperModalProps) => {
    const handleOnClose = () => {
        if (onClose) {
            onClose();
        }
    };
    return (
        <Modal mode="full-screen" open={open} onClose={handleOnClose} transparent>
            <div className="h-full flex flex-col">
                <div className="flex justify-end p-2 lg:p-4 md:absolute lg:relative md:right-0">
                    <Button className="rounded-full w-6 h-6 lg:w-8 lg:h-8" variant="flatWhite" onClick={handleOnClose}>
                        <FontAwesomeIcon icon={faXmark} />
                    </Button>
                </div>
                <div className="flex-grow flex justify-center">
                    <ImageSwiper images={images} useOldCloudinary={useOldCloudinary} />
                </div>
            </div>
        </Modal>
    );
};

export default ImageSwiperModal;
