import { CloudinaryImage } from "@/lib/constants/cloudinary";
import CloudImage from "../CloudImage";
import cloudinaryLoader, { cloudinaryCoyotivLoader } from "@/lib/setup/cloudinaryLoader";
import { useState } from "react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "../../../assets/css/myroom-swiper.css";

// import required modules
import { Navigation, Thumbs, Keyboard } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";
import { useTailwindBreakpoint } from "@/lib/hooks/useTailwindBreakpoint";

export type ImageSwiperProps = {
    images: CloudinaryImage[];
    useOldCloudinary?: boolean;
    singleImage?: boolean;
};

const ImageSwiper = ({ images, useOldCloudinary = false }: ImageSwiperProps) => {
    const [, breakpoint] = useTailwindBreakpoint();

    const [activeThumb, setActiveThumb] = useState<SwiperType | null | undefined>(null);
    const getImage = (image: CloudinaryImage, priority = false) => {
        const sizes: { sizes?: string } = {};
        switch (breakpoint) {
            case "lg":
            case "xl":
                sizes.sizes = "100vw"; 
                break;
            case "md":
                sizes.sizes = "80vw"; 
                break;
            default:
                sizes.sizes = "50vw"; 
                break;
        }

        return (
            <CloudImage
                {...sizes}
                className=" object-cover"
                key={image?.url}
                src={image?.url ?? ""}
                alt={image?.title}
                loader={useOldCloudinary ? cloudinaryCoyotivLoader : cloudinaryLoader}
                priority={priority}
            />
        );
    };

    const thumbsPerView = () => {
        switch (breakpoint) {
            case "lg":
            case "xl":
            case "md":
                if (images.length >= 8) return 8;
                return images.length;
            default:
                if (images.length >= 4) return 4;
                return images.length;
        }
    };
    return (
        <div className="flex min-h-full w-full">
            <div className="flex flex-col h-full w-full">
                <div className="flex max-w-full h-5/6 items-center">
                    <Swiper
                        modules={breakpoint === "sm" ? [Thumbs] : [Keyboard, Navigation, Thumbs]}
                        loop={true}
                        slidesPerView={1}
                        pagination={{
                            clickable: true,
                        }}
                        thumbs={{
                            swiper: activeThumb && !activeThumb.destroyed ? activeThumb : null,
                            multipleActiveThumbs: false,
                            slideThumbActiveClass: "swiper-slide-thumb-active",
                        }}
                        keyboard={true}
                        grabCursor={true}
                        navigation={breakpoint === "sm" || breakpoint === "md" ? false : true}
                        className="h-1/2 md:h-full md:w-1/2 lg:w-full thumbShow"
                        lazyPreloadPrevNext={3}
                    >
                        {images.map((image, index) => {
                            return (
                                <SwiperSlide key={index} className="py-8 md:py-2 lg:p-24">
                                    <div className="flex justify-center items-center h-full w-full">
                                        <div className="flex h-[100%] w-152">
                                            <div
                                                key={image.url}
                                                className={`relative w-full h-full`}
                                            >
                                                {getImage(image, index == 0)}
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
                <div className="flex h-1/6 max-w-full">
                    <Swiper
                        onSwiper={setActiveThumb}
                        loop={false}
                        grabCursor={true}
                        spaceBetween={breakpoint === "sm" ? 2 : 10}
                        slidesPerView={thumbsPerView()}
                        modules={[Navigation, Thumbs]}
                        className="thumbBtn h-full w-full"
                        coverflowEffect={{ slideShadows: true }}
                        thumbs={{
                            multipleActiveThumbs: false,
                            slideThumbActiveClass: "swiper-slide-thumb-active",
                        }}
                    >
                        {images.map((image, index) => (
                            <SwiperSlide key={index}>
                                <div className="absolute inset-0 bg-darkBlack opacity-50 z-10 slide-overlay" />
                                <div className="flex justify-center items-center h-80 w-80 md:h-10 md:w-10">
                                    {getImage(image, index == 0)}
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default ImageSwiper;
