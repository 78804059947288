import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalfStroke } from "@fortawesome/pro-solid-svg-icons";
import { faStar as faStarEmpty } from "@fortawesome/pro-regular-svg-icons";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";


type RatingProps = {
    rating: number;
    size?: SizeProp;
};

const RoomRating = ({ rating, size }: RatingProps) => {
    return (
        <div className="flex items-center gap-1">
            {[0, 1, 2, 3, 4].map((rate) => {
                let icon = faStarEmpty;
                if (rating > rate && rating <= rate + 0.5) {
                    icon = faStarHalfStroke;
                } else if (rating > rate) {
                    icon = faStar;
                }
                return <FontAwesomeIcon key={rate} icon={icon} size={size} className="text-primary-300" />;
            })}
        </div>
    );
};

export default RoomRating;
