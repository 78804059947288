import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";

type Props = {
    title: string;
    url: string;
    isLast: boolean;
    className?: string;
};

const BreadcrumbItem = ({ title, url, isLast, className = "" }: Props) => {
    return (
        <li className={`${isLast ? "overflow-hidden" : ""}`}>
            <div className="flex items-center overflow-hidden">
                <a href={url} className={`text-ellipsis overflow-hidden mr-2 text-base font-medium ${isLast ? "text-gray-500" : "text-primary-500"} hover:text-black whitespace-nowrap ${className}`}>{title}</a>
                {!isLast && <FontAwesomeIcon icon={faChevronRight} size="xs" className={`text-gray-500 ${className}`} />}
            </div>
        </li>
    );
};

export default BreadcrumbItem;
