export const reportError = ({ message }: { message: string }) => {
    // send the error to our logging service...
    console.log(message);
};

export const isEmptyObject = (obj: object) => {
    for (const prop in obj) {
        if (Object.hasOwn(obj, prop)) {
            return false;
        }
    }

    return true;
};
