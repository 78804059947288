import { MAP_DEFAULT_ZOOM, MAP_MAX_ZOOM, MAP_MIN_ZOOM } from "@/lib/constants/map";
import { useGoogleMap } from "@/lib/hooks/useGoogleMap";
import { useLanguage } from "@/lib/hooks/useLanguage";
import { Coordinates } from "@/lib/types/Map";
import { CircleF, GoogleMap } from "@react-google-maps/api";
import { useCallback, useState } from "react";


type Props = {
    location: Coordinates | undefined;
};

const RoomLocationMap = ({ location }: Props) => {
    const lang = useLanguage();
    const isLoaded = useGoogleMap(lang);
    const [, setMap] = useState<google.maps.Map | null>(null);

    const onLoad = useCallback((map: google.maps.Map) => {
        setMap(map);
    }, []);
    const onUnmount = useCallback(() => {
        setMap(null);
    }, []);

    return (
        <>
            {isLoaded && location && (
                <GoogleMap
                    mapContainerStyle={{ height: "100%", width: "100%" }}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    zoom={MAP_DEFAULT_ZOOM + 1}
                    center={location}
                    options={{
                        mapId: process.env.NEXT_PUBLIC_GOOGLE_MAPS_MAPID as string,
                        mapTypeControl: false,
                        fullscreenControl: false,
                        zoomControl: false,
                        minZoom: MAP_MIN_ZOOM,
                        maxZoom: MAP_MAX_ZOOM,
                        streetViewControl: false,
                        gestureHandling: "greedy",
                        scrollwheel: false,
                        panControl: false,
                        disableDoubleClickZoom: true,
                        draggable: false,
                    }}
                >
                    <CircleF
                        center={location}
                        radius={1000}
                        options={{
                            strokeColor: "#ffffff",
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillColor: "#14B5CB", // TODO: get from tailwind const.
                            fillOpacity: 0.35,
                        }}
                    />
                </GoogleMap>
            )}
        </>
    );
};

export default RoomLocationMap;
