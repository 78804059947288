import CloudImage from "@/lib/components/CloudImage";
import Button from "@/lib/components/Button";
import { cloudinaryCoyotivLoader } from "@/lib/setup/cloudinaryLoader";
import { CloudinaryImage } from "@/lib/constants/cloudinary";
import Text from "@/lib/components/Text";
import { useLanguage } from "@/lib/hooks/useLanguage";
import { useTranslation } from "@/lib/i18n/client";
import { SCREEN_SIZE } from "@/lib/constants/tailwind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/pro-solid-svg-icons";
import SimpleImageSwiper from "../ImageSwiper/SimpleImageSwiper";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

type Props = {
    as?: "dynamic" | "grid";
    images?: CloudinaryImage[];
    breakpoint?: SCREEN_SIZE;
    onShowAllImages?: () => void;
};

const HeaderImagePreview = ({ images, as = "dynamic", breakpoint = "sm", onShowAllImages }: Props) => {
    const lang = useLanguage();
    const { t } = useTranslation(lang, "search");
    const [activeImageIndex, setActiveImageIndex] = useState<number>(1);

    if (!images || images.length === 0) {
        return <></>;
    }

    const handleShowAll = () => {
        if (onShowAllImages) {
            onShowAllImages();
        }
    };

    const handleImageChange = (index: number) => {
        setActiveImageIndex(index + 1);
    };

    const getImage = (image: CloudinaryImage, last = false, priority = false, index = 0) => {
        const sizes: { sizes?: string } = {};
        if (as === "grid") {
            switch (breakpoint) {
                case "lg":
                case "xl":
                    sizes.sizes = "25vw"; // 1/4 of screen
                    break;
                case "md":
                    sizes.sizes = "33vw"; // 1/3 of screen
                    break;
                default:
                    sizes.sizes = "50vw"; // 1/2 of screen
                    break;
            }
        } else {
            if (breakpoint === "sm") {
                sizes.sizes = "100vw";
            } else {
                sizes.sizes = index === 0 ? "50vw" : "25vw";
            }
        }
        return (
            <div
                key={`${image?.url}-${uuidv4()}`}
                className={`w-full h-full relative overflow-hidden ${as === "grid" ? "aspect-square" : ""}`}
            >
                {last && (
                    <div className="text-white text-5xl absolute h-full w-full flex items-center backdrop-brightness-50 peer z-10 cursor-pointer">
                        <Text className="inline-block w-full text-center">{images.length}+</Text>
                    </div>
                )}
                {!image ? (
                    <div className="w-full h-full bg-blackAlpha-100"></div>
                ) : (
                    <CloudImage
                        {...sizes}
                        priority={priority}
                        className="aspect-video object-cover cursor-pointer peer-hover:scale-125 hover:scale-125 duration-500"
                        src={image.url}
                        alt={image.title}
                        loader={cloudinaryCoyotivLoader}
                    />
                )}
            </div>
        );
    };

    const imagePreviewComponent = () => {
        if (breakpoint != "sm") {
            return (
                <>
                    <div className="w-full md:w-auto md:basis-2/3 lg:basis-1/2 h-full flex">
                        {getImage(images[0], false, true, 0)}
                    </div>
                    <div className="hidden md:flex md:basis-1/3 lg:basis-1/2 h-full">
                        <div className="flex flex-col w-full">
                            <div className="basis-1/2 flex">{getImage(images[1], false, true, 1)}</div>
                            <div className="basis-1/2 flex">{getImage(images[2], false, true, 2)}</div>
                        </div>
                        <div className="flex-col w-full hidden lg:flex">
                            <div className="basis-1/2 flex">{getImage(images[3], false, true, 3)}</div>
                            <div className="basis-1/2 flex">{getImage(images[4], false, true, 4)}</div>
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <SimpleImageSwiper
                    images={images}
                    showPagination={false}
                    showNavigation={false}
                    useOldCloudinary={true}
                    onActiveSwiperChange={handleImageChange}
                />
            );
        }
    };

    if (as === "grid") {
        const getImagesCount = () => {
            if (breakpoint === "lg" || breakpoint === "xl") {
                return images.length >= 8 ? 8 : 4;
            } else if (breakpoint === "md") {
                return images.length >= 6 ? 6 : 3;
            } else {
                return images.length >= 4 ? 4 : 2;
            }
        };

        const cnt = getImagesCount();
        const isLast = (index: number) => {
            return images.length >= cnt ? index === cnt - 1 : index === cnt / 2 - 1;
        };

        return (
            <div className={`grid grid-cols-${cnt / 2} gap-2px`} onClick={handleShowAll}>
                {images.slice(0, images.length >= cnt ? cnt : cnt / 2).map((image, i) => getImage(image, isLast(i)))}
            </div>
        );
    } else {
        return (
            <div className="flex h-full w-full" onClick={handleShowAll}>
                {imagePreviewComponent()}
                <div className="absolute bottom-4 md:bottom-8 right-4 md:right-auto md:left-8 lg:left-auto lg:right-8 z-10">
                    <Button
                        variant="flatWhite"
                        className="hidden md:inline-block px-8 font-bold"
                        onClick={handleShowAll}
                    >
                        {t("room.show-all-images", { numImages: images.length })}
                    </Button>
                    <div
                        className="flex md:hidden flex-row bg-blackAlpha-500 text-white text-sm font-bold items-center px-4 py-3 rounded-md"
                        onClick={handleShowAll}
                    >
                        <FontAwesomeIcon icon={faImage} className="mr-4" />
                        <Text as="span" className="inline-block">
                            {activeImageIndex} / {images.length}
                        </Text>
                        {/* TODO: get actual number */}
                    </div>
                </div>
            </div>
        );
    }
};

export default HeaderImagePreview;
