import React from "react";
import BreadcrumbItem from "./BreadcrumbItem";


type LinkData = {
    title: string;
    url: string;
};

type Props = {
    links: LinkData[];
    className?: string;
};

const Breadcrumbs = ({ links, className = "" }: Props) => {
    return (
        <nav className="flex" aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-2 overflow-hidden">
                {links.map((link, index) => {
                    const isLast = index + 1 === links.length;
                    return (
                        <BreadcrumbItem key={link.url} title={link.title} url={link.url} isLast={isLast} className={className} />
                    );
                })}
            </ol>
        </nav>
    );
};

export default Breadcrumbs;
