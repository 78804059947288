"use client";

import { useEffect, useState } from "react";
import Container from "@/lib/components/Containers/Container";
import Text from "@/lib/components/Text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBed,
    faCheck,
    faCheckCircle,
    faChevronLeft,
    faChevronRight,
    faExclamationTriangle,
    faForkKnife,
    faHouseChimney,
    faHouseMedical,
    faImage,
    faLocationDot,
    faMartiniGlassCitrus,
    faShower,
    faUser,
    faXmark,
} from "@fortawesome/pro-solid-svg-icons";
import Link from "next/link";
import { useLanguage } from "@/lib/hooks/useLanguage";
import { getUrl, Urls } from "@/lib/constants/urls";
import { convertToListing, extractFilterUrlParameters, extractSearchUrlParameters } from "@/lib/utils/search";
import { useQueryParams } from "@/lib/hooks/useQueryParams";
import Breadcrumbs from "@/lib/components/Breadcrumbs";
import { useTranslation } from "@/lib/i18n/client";
import Badges, { BadgesProps } from "@/lib/components/Room/Badges";
import { convertToRoomDetailsProps } from "@/lib/utils/room-category";
import { CloudinaryImage } from "@/lib/constants/cloudinary";
import { allAmenitiesAndFacilities, AMENITIES_SHOWN, ROOMS_LEFT_WARNING } from "@/lib/constants/room";
import RoomLocationMap from "./RoomLocationMap";
import { Coordinates } from "@/lib/types/Map";
import { RoomProps } from "@/lib/components/Room";
import { useTailwindBreakpoint } from "@/lib/hooks/useTailwindBreakpoint";
import { faYelp } from "@fortawesome/free-brands-svg-icons";
import { formatNumber } from "@/lib/utils/general";
import RoomRating from "@/lib/components/RoomRating";
import LinkButton from "@/lib/components/Link";
import { usePathname } from "next/navigation";
import RoomSlider from "@/lib/components/RoomSlider";
import RoomSummary from "@/lib/components/RoomSummary";
import { MAX_NUMBER_OF_GUESTS } from "@/lib/constants/guestPicker";
import { API_ROOM_CATEGORY } from "@/lib/services/roomCategoryService";
import { BookingType } from "@/lib/types/Search";
import { API_SEARCH } from "@/lib/services/searchService";
import { Nearby, NearbyEntry, RoomCategory } from "@/lib/types/RoomCategory";
import ImageSwiperModal from "@/lib/components/ImageSwiper/ImageSwiperModal";
import HeaderImagePreview from "../../../../lib/components/HeaderImagePreview";
import { GoogleTagManagerEvents } from "@/lib/utils/googleTagEvents";
import { useAtomValue } from "jotai";
import { authAtom } from "@/lib/jotai/auth/authStore";
import { isEmptyObject } from "@/lib/services/utils";

export type RoomDetailsProps = {
    address: string;
    images: CloudinaryImage[];
    location: Coordinates;
    rulesAllowed: string[];
    rulesNotAllowed: string[];
} & RoomCategory &
    BadgesProps;

const RoomDetails = () => {
    const lang = useLanguage();
    const { t } = useTranslation(lang, "search");
    const searchUrlParams = useQueryParams();
    const roomId = searchUrlParams.get("roomId");
    const [room, setRoom] = useState<RoomDetailsProps | null>(null);
    const [roomSearch, setRoomSearch] = useState<RoomProps[] | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const searchBarProps = extractSearchUrlParameters(searchUrlParams);
    const filter = extractFilterUrlParameters(searchUrlParams);
    const [showAllAmenities, setShowAllAmenities] = useState(false);
    const [showAllRules, setShowAllRules] = useState(false);
    const [nearby, setNearby] = useState<Nearby | null>(null);
    const [, breakpoint] = useTailwindBreakpoint();
    const pathname = usePathname();
    const [amenities, setAmenities] = useState<string[]>([]);
    const [imagesModalVisible, setImagesModalVisible] = useState<boolean>(false);
    const auth = useAtomValue(authAtom);

    const backToListingParams = new URLSearchParams({ ...Object.fromEntries(searchUrlParams.entries()) });
    const userIdForGTM = auth.isLoggedIn ? auth.userInfo._id : undefined;
    backToListingParams.delete("roomId");

    const [locationParams, setLocationParams] = useState<Record<string, string>>(
        Object.fromEntries(backToListingParams.entries()),
    );

    const getRoom = async (roomId: string) => {
        if (!roomId) return;

        setIsLoading(true);

        const roomData = convertToRoomDetailsProps(await API_ROOM_CATEGORY.GetRoomCategory(roomId), lang);

        setRoom(roomData);

        // remove room params for breadcrumbs and update location
        const params = locationParams;
        delete params["roomId"];
        params["location"] = roomData.address;
        setLocationParams(params);
    };

    const getNearby = async (roomId: string) => {
        if (!roomId) return;
        const roomNearby = await API_ROOM_CATEGORY.GetRoomCategoryNearby(roomId);
        if (!isEmptyObject(roomNearby)) {
            setNearby(roomNearby);
        }
    };

    const getPropertySearch = async (propertyId: string) => {
        console.log("getPropertySearch called");
        if (!propertyId) return;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { location, ...searchBarNoLocation } = searchBarProps;
        const result = await API_SEARCH.Search(
            {
                // TODO: this should not force location!!!
                ...searchBarNoLocation,
                paginationSkip: 0,
                paginationLimit: 1000,
                sort: "price_asc",
                priceFrom: 0,
                language: lang,
                propertyId: propertyId,
            },
            filter,
        );

        const roomData: RoomProps[] = result?.results.map((resultRoom) => convertToListing(resultRoom, lang)) ?? [];

        setRoomSearch(roomData);
    };

    useEffect(() => {
        console.log("roomId called");
        if (!roomId) {
            // TODO: handle empty room parameter !!
        }
        getRoom(roomId ?? "");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomId]);

    useEffect(() => {
        if (!room) return;

        /*async function fireGTMViewRoomEvent(room: RoomDetailsProps) {
            try {
                const checkIn = format(new Date(searchBarProps.startDate ?? ""), SEARCH_API_DATE_FORMAT);
                const checkOut = format(new Date(searchBarProps.endDate ?? ""), SEARCH_API_DATE_FORMAT);
                const guestCount = (searchUrlParams.get("guestCount") as unknown as number) ?? 1;
                const bookingInDays = intervalToDuration({
                    start: searchBarProps.startDate as Date,
                    end: searchBarProps.endDate as Date,
                });

                console.log("***** Debug getRoomPrice 2");
                const resPrice = await API_SEARCH.GetRoomCategoryPrice({
                    checkinDate: searchBarProps.startDate as Date,
                    checkoutDate: searchBarProps.endDate as Date,
                    roomCategoryId: room._id,
                    numberOfAdults: guestCount,
                });

                const pricingAverage = resPrice[0].total / 100;

                GoogleTagManagerEvents.viewRoomDetailsPage(
                    lang,
                    room?.bookingType === undefined ? "booking_request" : "instant_request",
                    pricingAverage,
                    {
                        item_id: room?._id ?? "",
                        hotel_name: room.property.name ?? "",
                        hotel_type: room.roomType ?? "",
                        hotel_room_name: room.title,
                        hotel_city: room.property.address.city ?? "",
                        price: pricingAverage,
                    } as unknown as RoomItem,
                    guestCount,
                    bookingInDays.days as number,
                    checkIn,
                    checkOut,
                    "search_result_page",
                    auth.isLoggedIn ? auth.userInfo._id : undefined,
                );
            } catch (error) {
                console.log("Tried to trigger GTM viewRoomDetailsPage but failed with: ", error);
            }
        }
*/
        console.log("room called");

        getPropertySearch(room.property._id ?? "");

        setAmenities(room?.amenities.filter((a) => amenityExists(a)));

        // TODO: has to be reactivated
        // fireGTMViewRoomEvent(room);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [room]);

    useEffect(() => {
        console.log("locationParams called");

        getNearby(roomId ?? "");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationParams]);

    const amenityExists = (amenityName: string) => {
        return allAmenitiesAndFacilities.find((a) => a.value === amenityName);
    };

    const getAmenity = (amenityName: string) => {
        const amenity = allAmenitiesAndFacilities.find((a) => a.value === amenityName);

        return (
            <div key={amenity?.key ?? amenityName} className="whitespace-nowrap flex flex-nowrap items-center">
                {amenity && (
                    <>
                        <FontAwesomeIcon className="mr-2" icon={amenity.icon} />
                        {t(amenity.key)}
                    </>
                )}
            </div>
        );
    };

    const getRatingBadge = (rating: number) => {
        let ratingText = "";
        let ratingColor = "";
        if (rating > 4) {
            ratingText = t("room.rating.excellent");
            ratingColor = "bg-green-500";
        } else if (rating > 3) {
            ratingText = t("room.rating.good");
            ratingColor = "bg-green-500";
        } else if (rating > 2) {
            ratingText = t("room.rating.average");
            ratingColor = "bg-green-500";
        } else if (rating > 1) {
            ratingText = t("room.rating.poor");
            ratingColor = "bg-yellow-500";
        } else {
            ratingText = t("room.rating.veryPoor");
            ratingColor = "bg-red-500";
        }
        return (
            <div
                className={`${ratingColor} hidden md:flex uppercase text-center text-white grow items-center text-xs rounded-md justify-center`}
            >
                {ratingText}
            </div>
        );
    };

    const renderNearby = (entries: NearbyEntry[]) => {
        if (entries) {
            const entriesAsArray = [...entries].sort((a, b) => a.distance - b.distance);
            return (
                <div className="flex flex-col gap-3">
                    {entriesAsArray.map((entry) => (
                        <div key={entry.id} className="flex flex-row">
                            <Text as="span" className="md:basis-1/4 grow">
                                <Link href={entry.url} className="hover:text-primary-500" target="_blank">
                                    {entry.name}{" "}
                                    <span className="text-gray-500">
                                        ({formatNumber(lang, entry.distance / 1000, 1)} km)
                                    </span>
                                </Link>
                            </Text>
                            <div className="md:basis-1/4 flex flex-row gap-4">
                                <RoomRating rating={entry.rating} size="sm" />
                                {getRatingBadge(entry.rating)}
                            </div>
                            <Text as="span" className="hidden md:inline basis-1/4 text-gray-500 text-right">
                                <Link href={entry.url} className="hover:text-primary-500" target="_blank">
                                    {t("room.reviews-num", { num: entry.review_count })}
                                </Link>
                            </Text>
                        </div>
                    ))}
                </div>
            );
        } else {
            return null;
        }
    };

    const renderRoomsLeft = () => {
        if (!roomSearch!.length) return <></>;

        return (
            <div
                className={`${
                    roomSearch!.length > ROOMS_LEFT_WARNING ? "hidden lg:block" : ""
                } text-center py-5 lg:py-0 border border-red-500 bg-red-200 lg:bg-transparent lg:border-0 rounded-md mx-3 md:mx-8 mb-6 mt-2 lg:m-0 ${
                    roomSearch && roomSearch?.length <= ROOMS_LEFT_WARNING ? "lg:text-red-500" : "lg:text-black"
                }`}
            >
                {roomSearch!.length <= ROOMS_LEFT_WARNING && (
                    <>
                        <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                        <Text className="font-bold">
                            {t("room.only-num-rooms-left", { count: roomSearch!.length })}
                        </Text>
                    </>
                )}
                {roomSearch!.length > ROOMS_LEFT_WARNING && (
                    <Text className="font-bold">{t("room.num-rooms-available", { count: roomSearch!.length })}</Text>
                )}
            </div>
        );
    };
    // Commented out due to https://myroom24.atlassian.net/browse/I2-480
    // const onHostImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    //     // hides parent of image, to no break design, if image can't be loaded - we have cases where we get image url but it doesn't exist in cloudinary
    //     if (e.currentTarget?.parentNode) {
    //         (e.currentTarget.parentNode as HTMLDivElement).style.display = "none";
    //     }
    // };

    const getBreadcrumbLinks = () => {
        const links = [{ title: t("home"), url: getUrl(Urls.home.index, lang) }];
        if (room?.property.address.city) {
            links.push({
                title: room?.property.address.city ?? "...",
                url: getUrl(Urls.search.index, lang, {}, new URLSearchParams(locationParams).toString()),
            });
        }
        if (room?.name) {
            links.push({
                title: room?.name ?? "...",
                url: `${pathname}?${searchUrlParams.toString()}`,
            });
        }

        return links;
    };

    const handleShowAllImages = () => {
        GoogleTagManagerEvents.clickOnGalleryViewForRoomDetails(lang, userIdForGTM);
        setImagesModalVisible(true);
    };

    const handleCloseImageSwiper = () => {
        setImagesModalVisible(false);
    };

    return (
        <div className="w-full h-full mt-header-small lg:mt-header bg-white lg:bg-transparent">
            {/* <ImageSwiper images={room?.images} useOldCloudinary={true} /> */}

            <ImageSwiperModal
                images={room?.images ?? []}
                useOldCloudinary={true}
                open={imagesModalVisible}
                onClose={handleCloseImageSwiper}
            />
            <div className="w-full h-64 md:h-80 lg:h-128 relative">
                <HeaderImagePreview
                    images={room?.images}
                    breakpoint={breakpoint}
                    onShowAllImages={handleShowAllImages}
                />
            </div>
            <Container noYMargin noXMargin maxWidth={10} className="mt-3 md:mt-4 lg:mt-10 lg:px-12 xl:px-16">
                <div className="relative w-full h-full flex flex-col">
                    <nav className="flex px-3 md:px-8 lg:px-0">
                        <Link
                            href={getUrl(Urls.search.index, lang, {}, backToListingParams.toString())}
                            className="text-base md:text-xl font-medium text-black hover:text-primary-500 mb-4"
                        >
                            <FontAwesomeIcon icon={faChevronLeft} size="xs" /> {t("room.back-to-listings")}
                        </Link>
                    </nav>
                    <div className="w-full relative flex flex-row mb-4 md:mb-12 gap-4">
                        <div className="lg:basis-2/3 text-black">
                            <div className="flex flex-col bg-white lg:pt-6 mb-8">
                                <div className="w-full px-3 grid grid-flow-col auto-cols-fr items-center md:px-8 pb-4 lg:pb-8">
                                    <div className="grow col-span-2 flex flex-col h-full md:gap-2 mt-2 md:mt-0">
                                        <Breadcrumbs links={getBreadcrumbLinks()} className="mb-0 lg:mb-4" />
                                        {/* Hiding due to business requiremets https://myroom24.atlassian.net/browse/I2-480 */}
                                        {/* <Text
                                            as="h1-seo"
                                            className="font-semibold max-sm:text-xl sm:text-2xl whitespace-break-spaces md:mt-4 lg:mt-0"
                                        >
                                            {room?.property.name}
                                        </Text> */}
                                        <Text
                                            as="h2"
                                            className="font-semibold max-sm:text-xl sm:text-2xl whitespace-break-spaces"
                                        >
                                            {room?.name}
                                        </Text>
                                        <div
                                            className={`flex items-start ${
                                                room?.isInstantBooking ?? room?.isFeatured ?? room?.isSuperHost ?? false
                                                    ? "pt-2 md:py-6"
                                                    : ""
                                            }`}
                                        >
                                            <Badges
                                                padding="large"
                                                fontSize="xs"
                                                lang={lang}
                                                isInstantBooking={room?.isInstantBooking ?? false}
                                                isFeatured={room?.isFeatured ?? false}
                                                isSuperHost={room?.isSuperHost ?? false}
                                            />
                                        </div>
                                        <div className="text-black mt-2 md:mt-0">
                                            <FontAwesomeIcon icon={faLocationDot} size="xs" className="mr-2" />
                                            <Text as="span" className="font-medium">
                                                {room?.address}
                                            </Text>
                                        </div>
                                    </div>
                                    <div className="text-right text-gray-500 font-medium hidden lg:flex flex-col items-end h-full text-base">
                                        <div className="hidden lg:block">
                                            {t("room.property-id")}: {room?.property._id}
                                        </div>
                                        <div className="hidden lg:block">DS Id: {room?.onDerbySoft}</div>
                                        {/* Commented out due to business reasoning https://myroom24.atlassian.net/browse/I2-480 */}
                                        {/* <div className="w-full flex flex-col mt-8 md:mt-0 lg:py-2 items-end">
                                            {room && (
                                                <div className="w-full max-w-[56px] max-h-[56px] md:max-w-[132px] md:max-h-[132px] h-full relative items-start flex flex-col aspect-square">
                                                    <CloudImage
                                                        sizes={breakpoint === "sm" ? "56px" : "132px"}
                                                        hideOnError={true}
                                                        className="object-scale-down"
                                                        src={room?.property.logo ?? room?.property.company.logo}
                                                        alt=""
                                                        loader={cloudinaryCoyotivLoader}
                                                    />
                                                </div>
                                            )}
                                        </div> */}
                                        <div className="hidden lg:block">{roomSearch && renderRoomsLeft()}</div>
                                    </div>
                                </div>
                                <div className="lg:hidden">{roomSearch && renderRoomsLeft()}</div>
                                <div className="border-t border-b border-gray-300 flex flex-wrap">
                                    <div className="basis-1/2 md:basis-1/4 grow-0 shrink-0 text-center items-center flex flex-col py-4 md:py-8 md:border-r border-gray-300">
                                        <FontAwesomeIcon
                                            icon={faHouseChimney}
                                            size="3x"
                                            className="text-gray-300 my-4"
                                        />
                                        <Text as="span" className="font-medium">
                                            {t("room.type-panel")}
                                        </Text>
                                        <Text as="span" className="font-bold capitalize">
                                            {room?.roomType ? t(`room.type.${room?.roomType ?? ""}`) : "-"}
                                        </Text>
                                    </div>
                                    <div className="basis-1/2 md:basis-1/4 grow-0 shrink-0 text-center items-center flex flex-col py-4 md:py-8 md:border-r border-gray-300">
                                        <FontAwesomeIcon icon={faUser} size="3x" className="text-gray-300 my-4" />
                                        <Text as="span" className="font-medium">
                                            {t("room.accomodation")}
                                        </Text>
                                        <Text as="span" className="font-bold capitalize">
                                            {t("room.guests", { count: room?.maxOccupancy ?? 0 })}
                                        </Text>
                                    </div>
                                    <div className="basis-1/2 md:basis-1/4 grow-0 shrink-0 text-center items-center flex flex-col py-4 md:py-8 md:border-r border-gray-300">
                                        <FontAwesomeIcon icon={faBed} size="3x" className="text-gray-300 my-4" />
                                        <Text as="span" className="font-medium">
                                            {t("room.bedrooms-label")}
                                        </Text>
                                        <Text as="span" className="font-bold capitalize">
                                            {t("room.bedrooms", { count: room?.numberOfBedrooms ?? 0 })} /{" "}
                                            {t("room.beds", { count: room?.numberOfBeds ?? 0 })}
                                        </Text>
                                    </div>
                                    <div className="basis-1/2 md:basis-1/4 grow-0 shrink-0 text-center items-center flex flex-col py-4 md:py-8">
                                        <FontAwesomeIcon icon={faShower} size="3x" className="text-gray-300 my-4" />
                                        <Text as="span" className="font-medium">
                                            {t("room.bathrooms-label")}
                                        </Text>
                                        <Text as="span" className="font-bold capitalize">
                                            {t("room.baths", { count: room?.numberOfBathrooms ?? 0 })}
                                        </Text>
                                    </div>
                                </div>
                                <div className="px-3 md:px-8 py-6 md:py-8 border-b border-gray-300">
                                    <Text as="h2" className="font-bold text-xl pb-8">
                                        {t("room.about-the-listing")}
                                    </Text>
                                    <Text as="p" className="font-lg font-medium">
                                        {lang === "de" && room?.description_de
                                            ? room?.description_de
                                            : room?.description}
                                    </Text>
                                </div>
                                <div className="px-3 md:px-8 py-6 md:py-8 border-b border-gray-300">
                                    <Text as="h2" className="font-bold text-xl pb-3 md:pb-8">
                                        {t("room.details")}
                                    </Text>
                                    <div className="flex flex-col md:flex-row gap-2 md:gap-8">
                                        <div className="flex flex-col gap-2">
                                            <Text as="span" className="font-lg font-medium">
                                                <FontAwesomeIcon icon={faChevronRight} size="2xs" /> {t("room.room-id")}
                                                : <span className="font-bold">{room?._id}</span>
                                            </Text>
                                            <Text as="span" className="font-lg font-medium">
                                                <FontAwesomeIcon icon={faChevronRight} size="2xs" />{" "}
                                                {t("room.guests-label")}:{" "}
                                                <span className="font-bold">{room?.maxOccupancy}</span>
                                            </Text>
                                            <Text as="span" className="font-lg font-medium">
                                                <FontAwesomeIcon icon={faChevronRight} size="2xs" />{" "}
                                                {t("room.bedrooms-label")}:{" "}
                                                <span className="font-bold">{room?.numberOfBedrooms}</span>
                                            </Text>
                                            <Text as="span" className="font-lg font-medium">
                                                <FontAwesomeIcon icon={faChevronRight} size="2xs" />{" "}
                                                {t("room.beds-label")}:{" "}
                                                <span className="font-bold">{room?.numberOfBeds}</span>
                                            </Text>
                                            <Text as="span" className="font-lg font-medium">
                                                <FontAwesomeIcon icon={faChevronRight} size="2xs" />{" "}
                                                {t("room.bathrooms-label")}:{" "}
                                                <span className="font-bold">{room?.numberOfBathrooms}</span>
                                            </Text>
                                        </div>
                                        <div className="flex flex-col gap-2">
                                            <Text as="span" className="font-lg font-medium">
                                                <FontAwesomeIcon icon={faChevronRight} size="2xs" />{" "}
                                                {t("room.check-in-after")}:{" "}
                                                <span className="font-bold">{room?.checkinTime || "16:00"}</span>
                                            </Text>
                                            <Text as="span" className="font-lg font-medium">
                                                <FontAwesomeIcon icon={faChevronRight} size="2xs" />{" "}
                                                {t("room.check-out-before")}:{" "}
                                                <span className="font-bold">
                                                    {!room?.checkoutTime ? "11:00" : room.checkoutTime}
                                                </span>
                                            </Text>
                                            <Text as="span" className="font-lg font-medium">
                                                <FontAwesomeIcon icon={faChevronRight} size="2xs" />{" "}
                                                {t("room.type-panel")}:{" "}
                                                <span className="font-bold">
                                                    {room?.roomType ? t(`room.type.${room?.roomType ?? ""}`) : ""}
                                                </span>
                                            </Text>
                                            {room?.size !== 0 && (
                                                <Text as="span" className="font-lg font-medium">
                                                    <FontAwesomeIcon icon={faChevronRight} size="2xs" />{" "}
                                                    {t("room.room-size")}:{" "}
                                                    <span className="font-bold">{room?.size} m²</span>
                                                </Text>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="px-3 md:px-8 py-6 md:py-8">
                                    <Text as="h2" className="font-bold text-xl pb-8">
                                        {t("room.cancellation-policy")}
                                    </Text>
                                    <Text as="p" className="font-lg font-medium">
                                        <Link
                                            href={getUrl(Urls.cancellationPolicy.index, lang)}
                                            className="underline hover:text-primary-500"
                                            target="_blank"
                                        >
                                            {t("room.cancellation-policy-text")}
                                        </Link>
                                    </Text>
                                </div>
                                <div className="py-3 md:py-8">
                                    <HeaderImagePreview
                                        as="grid"
                                        images={room?.images}
                                        breakpoint={breakpoint}
                                        onShowAllImages={handleShowAllImages}
                                    />
                                </div>
                                <div className="px-3 md:px-8 py-4 md:pb-8">
                                    <Text as="h2" className="font-bold text-xl pb-4">
                                        {t("room.features")}
                                    </Text>
                                    <div className="flex pb-8">
                                        <Text as="p" className="font-lg font-bold grow">
                                            {t("room.amenities-facilities")}
                                        </Text>
                                        {amenities.length > AMENITIES_SHOWN && (
                                            <Text
                                                as="p"
                                                className="font-lg font-bold underline hover:text-primary-500 cursor-pointer"
                                                onClick={() => {
                                                    GoogleTagManagerEvents.clickOnShowMoreAmenities(
                                                        lang,
                                                        auth.isLoggedIn ? auth.userInfo._id : undefined,
                                                    );
                                                    setShowAllAmenities(!showAllAmenities);
                                                }}
                                            >
                                                {t("show-more")}
                                            </Text>
                                        )}
                                    </div>
                                    <div className="flex flex-wrap gap-8 md:gap-10">
                                        {amenities
                                            .slice(0, showAllAmenities ? amenities.length : AMENITIES_SHOWN)
                                            .map((a) => getAmenity(a))}
                                    </div>
                                </div>
                                <div className="mt-3 md:mt-8 h-72 md:h-96 relative">
                                    <RoomLocationMap location={room?.location} />
                                </div>
                                <div className="px-3 md:px-8 py-3 md:py-8 border-b border-gray-300">
                                    <Text as="h2" className="font-bold text-xl pb-4">
                                        {t("room.terms-rules")}
                                    </Text>
                                    <div className="flex pb-4">
                                        <div className="basis-1/2 md:basis-1/3 flex flex-col">
                                            {room?.rulesAllowed.map((rule) => (
                                                <Text key={rule} as="span">
                                                    <FontAwesomeIcon
                                                        className="mr-2 text-green-500"
                                                        size="sm"
                                                        icon={faCheck}
                                                    />
                                                    {t(`room.rules.${rule}`)}
                                                </Text>
                                            ))}
                                        </div>
                                        <div className="basis-1/2 md:basis-1/3 flex flex-col">
                                            {room?.rulesNotAllowed.map((rule) => (
                                                <Text key={rule} as="span">
                                                    <FontAwesomeIcon
                                                        className="mr-2 text-red-500"
                                                        size="sm"
                                                        icon={faXmark}
                                                    />
                                                    {t(`room.rules.${rule}`)}
                                                </Text>
                                            ))}
                                        </div>
                                    </div>
                                    {room?.rules.additional && (
                                        <>
                                            <Text as="p" className="font-lg font-bold pb-4">
                                                {t("room.additional-rules")}
                                            </Text>
                                            <div className="md:flex">
                                                <Text as="p" className="grow font-base whitespace-pre-line">
                                                    {showAllRules && room.rules.additional
                                                        ? room.rules.additional
                                                        : room.rules.additional.split(/\r?\n/)[0]}
                                                </Text>
                                                <Text
                                                    as="p"
                                                    className="inline-block w-full md:inline md:w-auto text-right font-lg font-bold underline hover:text-primary-500 cursor-pointer whitespace-nowrap"
                                                    onClick={() => setShowAllRules(!showAllRules)}
                                                >
                                                    {showAllRules ? t("show-less") : t("show-more")}
                                                </Text>
                                            </div>
                                        </>
                                    )}
                                </div>
                                {nearby?.food && (
                                    <div className="px-3 md:px-8 pt-3 md:py-8">
                                        <Text as="h2" className="font-bold text-xl pb-4">
                                            {t("room.nearby")}
                                        </Text>
                                        <Text as="h3" className="font-bold text-base pb-4">
                                            <FontAwesomeIcon className="mr-2" icon={faForkKnife} />
                                            {t("room.yelp.food")}
                                        </Text>
                                        {nearby && renderNearby(nearby?.food)}
                                        <Text as="h3" className="font-bold text-base pb-4 pt-6">
                                            <FontAwesomeIcon className="mr-2" icon={faHouseMedical} />
                                            {t("room.yelp.health-medical")}
                                        </Text>
                                        {nearby && renderNearby(nearby?.health)}
                                        <Text as="h3" className="font-bold text-base pb-4 pt-6">
                                            <FontAwesomeIcon className="mr-2" icon={faImage} />
                                            {t("room.yelp.arts-entertainment")}
                                        </Text>
                                        {nearby && renderNearby(nearby?.arts)}
                                        <Text as="h3" className="font-bold text-base pb-4 pt-6">
                                            <FontAwesomeIcon className="mr-2" icon={faMartiniGlassCitrus} />
                                            {t("room.yelp.nightlife")}
                                        </Text>
                                        {nearby && renderNearby(nearby?.nightlife)}
                                        <Text as="span" className="text-base md:pb-4 mt-8 inline-block">
                                            {t("room.yelp.powered-by")} <FontAwesomeIcon icon={faYelp} />{" "}
                                            <span className="font-bold">Yelp</span>
                                        </Text>
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col bg-white border-t border-b border-gray-300 lg:border-0">
                                {/* Hiding due to business requiremets https://myroom24.atlassian.net/browse/I2-480 */}
                                {/* <div className="flex gap-2 md:gap-6 px-3 md:px-8 py-3 md:py-8">
                                    {room && (
                                        <div className="w-full max-w-[132px] h-full aspect-square relative items-start flex flex-col">
                                            <CloudImage
                                                sizes="132px"
                                                customOnError={onHostImageError}
                                                className="object-scale-down"
                                                src={room?.property.logo ?? room?.property.company.logo}
                                                alt=""
                                                loader={cloudinaryCoyotivLoader}
                                            />
                                        </div>
                                    )}
                                    <div className="grow flex flex-col gap-4">
                                        {room && (
                                            <Text as="h2" className="font-bold text-base md:text-xl">
                                                {t("room.hosted-by")} {room.property.name}
                                            </Text>
                                        )}
                                        <div className="flex flex-row gap-2 items-center text-sm">
                                            <FontAwesomeIcon icon={faLocationDot} size="sm" className="md:mr-2" />
                                            <Text as="span" className="font-medium mr-4 md:mr-8">
                                                {translateISOCountry(room?.property.address.country ?? "en", lang)}
                                            </Text>
                                            <RoomRating rating={5} size={breakpoint === "sm" ? "xs" : "1x"} />
                                            <Text as="span" className="text-gray-500">
                                                {t("room.rating.excellent")}
                                            </Text>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="flex flex-col px-3 md:px-8 py-8 gap-2">
                                    <Text as="span" className="font-bold">
                                        {t("room.profile-status")}
                                    </Text>
                                    <Text as="span" className="text-green-500 font-medium">
                                        <FontAwesomeIcon icon={faCheckCircle} size="sm" className="mr-2" />
                                        {t("room.verified")}
                                    </Text>
                                </div>
                                <div className="flex flex-col md:flex-row w-full gap-2 pb-8 px-3 md:px-8">
                                    <div className="basis-1/2 w-full">
                                        <LinkButton variant="gray" href={getUrl(Urls.contactUs.index, lang)}>
                                            {t("room.contact-support")}
                                        </LinkButton>
                                    </div>
                                    <div className="basis-1/2 w-full">
                                        <LinkButton
                                            variant="gray"
                                            href={getUrl(
                                                Urls.property.index,
                                                lang,
                                                null,
                                                `propertyId=${room?.property._id}`,
                                            )}
                                        >
                                            {t("room.view-profile")}
                                        </LinkButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="fixed z-30 bottom-0 w-full shadow-lg-top lg:z-auto lg:bottom-auto lg:shadow-none lg:w-auto lg:relative lg:basis-1/3 lg:block">
                            <div className="sticky top-32 w-full bg-white flex flex-col">
                                <RoomSummary
                                    securityDeposit={(room?.pricing.security as number) ?? 0}
                                    roomTitle={room?.title || ""}
                                    bookingType={room?.bookingType as BookingType}
                                    maxOccupancy={room?.maxOccupancy ?? MAX_NUMBER_OF_GUESTS}
                                    onChange={() => {}}
                                    isLoadingProp={isLoading}
                                    setIsLoading={setIsLoading}
                                    companyId={room?.property.company._id}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-full relative mb-12 px-3 md:px-8 lg:px-0">
                        <Text as="h2" className="font-bold text-xl pb-2">
                            {t("room.nearby-listings")}
                        </Text>
                        <Text as="p" className="pb-8">
                            {t("room.explore-more-rentals")}
                        </Text>
                        <RoomSlider params={locationParams} style="home" limit={10} />
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default RoomDetails;
