import { faMailbox, faWifi, faRefrigerator, faKitchenSet, faTv, faRestroom, faTemperatureHalf, faWashingMachine, faWheelchair, faMicrowave, faCoffeePot, faShower, faClock, faSuitcaseRolling, faBroomWide, faAirConditioner, faFence, faMartiniGlassCitrus, faBath, faMugSaucer, faBellOn, faSensorCloud, faCards, faChairOffice, faPodium, faDryer, faElevator, faHouseUser, faFamily, faFireExtinguisher, faSuitcaseMedical, faSquareParking, faSpa, faPlateUtensils, faDumbbell, faScissors, faWind, faVault, faFireplace, faWaterLadder, faClothesHanger, faOven, faCouch, faLoveseat, faBanSmoking, faPawSimple, faForkKnife, faBellConcierge, faIdCard, faHeat, faUserPolice, faShop, faCartShopping, faSensorFire, faSmoking, faHouseDay } from "@fortawesome/pro-solid-svg-icons";


export const allHouseRules = [
    // {
    //     value: "smoking-allowed",
    //     key: "filter.houserules.smoking-allowed",
    // },
    {
        value: "pets-allowed",
        key: "filter.houserules.pets-allowed",
    },
    {
        value: "family-kid-friendly",
        key: "filter.houserules.children-allowed",
    },
];

export const AMENITIES_SHOWN = 10; // how many amenities are shown on room page by default (before expanding/clicking "show more")
export const ROOMS_LEFT_WARNING = 3; // show exclamation icon and red text if there are less than this number of rooms available for property

export const filterAmenitiesAndFacilities = [
    {
        value: "postal-registration",
        key: "filter.amenities.postal-registration",
        icon: faMailbox,
    },
    {
        value: "wi-fi",
        key: "filter.amenities.wi-fi",
        icon: faWifi,
    },
    {
        value: "fridge-minibar",
        key: "filter.amenities.fridge-minibar",
        icon: faRefrigerator,
    },
    {
        value: "private-kitchen",
        key: "filter.amenities.private-kitchen",
        icon: faKitchenSet,
    },
    {
        value: "tv",
        key: "filter.amenities.tv",
        icon: faTv,
    },
    {
        value: "private-bathroom",
        key: "filter.amenities.private-bathroom",
        icon: faRestroom,
    },
    {
        value: "heating",
        key: "filter.amenities.heating",
        icon: faTemperatureHalf,
    },
    {
        value: "washing-machine",
        key: "filter.amenities.washing-machine",
        icon: faWashingMachine,
    },
    {
        value: "pets",
        key: "filter.amenities.pets",
        icon: faPawSimple,
    },
    {
        value: "wheelchair-accessible",
        key: "filter.amenities.wheelchair-accessible",
        icon: faWheelchair,
    },
    {
        value: "microwave",
        key: "filter.amenities.microwave",
        icon: faMicrowave,
    },
    {
        value: "tea-coffee-maker",
        key: "filter.amenities.tea-coffee-maker",
        icon: faCoffeePot,
    }
];

export const allAmenitiesAndFacilities = [
    ...filterAmenitiesAndFacilities,
    {
        value: "shower",
        key: "filter.amenities.shower",
        icon: faShower,
    },
    {
        value: "24-hour-checkin",
        key: "filter.amenities.24-hour-checkin",
        icon: faSuitcaseRolling,
    },
    {
        value: "24-hour-reception",
        key: "filter.amenities.24-hour-reception",
        icon: faClock,
    },
    {
        value: "additional-cleaning",
        key: "filter.amenities.additional-cleaning",
        icon: faBroomWide,
    },
    {
        value: "air-conditioning",
        key: "filter.amenities.air-conditioning",
        icon: faAirConditioner,
    },
    {
        value: "balcony-terrace",
        key: "filter.amenities.balcony-terrace",
        icon: faFence,
    },
    {
        value: "bar",
        key: "filter.amenities.bar",
        icon: faMartiniGlassCitrus,
    },
    {
        value: "bathtub",
        key: "filter.amenities.bathtub",
        icon: faBath,
    },
    {
        value: "breakfast",
        key: "filter.amenities.breakfast",
        icon: faMugSaucer,
    },
    {
        value: "breakfast-additional",
        key: "filter.amenities.breakfast-additional",
        icon: faMugSaucer,
    },
    {
        value: "breakfast-area",
        key: "filter.amenities.breakfast-area",
        icon: faMugSaucer,
    },
    {
        value: "buzzer-wireless-intercom",
        key: "filter.amenities.buzzer-wireless-intercom",
        icon: faBellOn,
    },
    {
        value: "cable-tv",
        key: "filter.amenities.cable-tv",
        icon: faTv,
    },
    {
        value: "cafe",
        key: "filter.amenities.cafe",
        icon: faMugSaucer,
    },
    {
        value: "carbon-monoxide-detector",
        key: "filter.amenities.carbon-monoxide-detector",
        icon: faSensorCloud,
    },
    {
        value: "casino",
        key: "filter.amenities.casino",
        icon: faCards,
    },
    {
        value: "co-working-area",
        key: "filter.amenities.co-working-area",
        icon: faChairOffice,
    },
    {
        value: "conference-room",
        key: "filter.amenities.conference-room",
        icon: faPodium,
    },
    {
        value: "disability-friendly",
        key: "filter.amenities.disability-friendly",
        icon: faWheelchair,
    },
    {
        value: "dryer-room",
        key: "filter.amenities.dryer-room",
        icon: faDryer,
    },
    {
        value: "elevator",
        key: "filter.amenities.elevator",
        icon: faElevator,
    },
    {
        value: "essentials",
        key: "filter.amenities.essentials",
        icon: faHouseUser,
    },
    {
        value: "family-kid-friendly",
        key: "filter.amenities.family-kid-friendly",
        icon: faFamily,
    },
    {
        value: "fire-extinguisher",
        key: "filter.amenities.fire-extinguisher",
        icon: faFireExtinguisher,
    },
    {
        value: "first-aid-kit",
        key: "filter.amenities.first-aid-kit",
        icon: faSuitcaseMedical,
    },
    {
        value: "free-parking",
        key: "filter.amenities.free-parking",
        icon: faSquareParking,
    },
    {
        value: "free-spa-wellness",
        key: "filter.amenities.free-spa-wellness",
        icon: faSpa,
    },
    {
        value: "fridge",
        key: "filter.amenities.fridge",
        icon: faRefrigerator,
    },
    {
        value: "full-board",
        key: "filter.amenities.full-board",
        icon: faPlateUtensils,
    },
    {
        value: "gym",
        key: "filter.amenities.gym",
        icon: faDumbbell,
    },
    {
        value: "hairdresser",
        key: "filter.amenities.hairdresser",
        icon: faScissors,
    },
    {
        value: "hairdryer",
        key: "filter.amenities.hairdryer",
        icon: faWind,
    },
    {
        value: "half-board",
        key: "filter.amenities.half-board",
        icon: faPlateUtensils,
    },
    {
        value: "hotel-safe",
        key: "filter.amenities.hotel-safe",
        icon: faVault,
    },
    {
        value: "indoor-fireplace",
        key: "filter.amenities.indoor-fireplace",
        icon: faFireplace,
    },
    {
        value: "indoor-pool",
        key: "filter.amenities.indoor-pool",
        icon: faWaterLadder,
    },
    {
        value: "iron",
        key: "filter.amenities.iron",
        icon: faClothesHanger,
    },
    {
        value: "kitchenette",
        key: "filter.amenities.kitchenette",
        icon: faOven,
    },
    {
        value: "laundry-facilities",
        key: "filter.amenities.laundry-facilities",
        icon: faWashingMachine,
    },
    {
        value: "laundry-room",
        key: "filter.amenities.laundry-room",
        icon: faWashingMachine,
    },
    {
        value: "laundry-service",
        key: "filter.amenities.laundry-service",
        icon: faWashingMachine,
    },
    {
        value: "laundry-service-additional",
        key: "filter.amenities.laundry-service-additional",
        icon: faWashingMachine,
    },
    {
        value: "lobby",
        key: "filter.amenities.lobby",
        icon: faCouch,
    },
    {
        value: "lounge",
        key: "filter.amenities.lounge",
        icon: faLoveseat,
    },
    {
        value: "massage",
        key: "filter.amenities.massage",
        icon: faSpa,
    },
    {
        value: "monthly-cleaning",
        key: "filter.amenities.monthly-cleaning",
        icon: faBroomWide,
    },
    {
        value: "non-smoking",
        key: "filter.amenities.non-smoking",
        icon: faBanSmoking,
    },
    {
        value: "outdoor-pool",
        key: "filter.amenities.outdoor-pool",
        icon: faWaterLadder,
    },
    {
        value: "parking-place",
        key: "filter.amenities.parking-place",
        icon: faSquareParking,
    },
    {
        value: "pets-allowed",
        key: "filter.amenities.pets-allowed",
        icon: faPawSimple,
    },
    {
        value: "pool",
        key: "filter.amenities.pool",
        icon: faWaterLadder,
    },
    {
        value: "pub",
        key: "filter.amenities.pub",
        icon: faMartiniGlassCitrus,
    },
    {
        value: "restaurant",
        key: "filter.amenities.restaurant",
        icon: faForkKnife,
    },
    {
        value: "room-service",
        key: "filter.amenities.room-service",
        icon: faBellConcierge,
    },
    {
        value: "safe",
        key: "filter.amenities.safe",
        icon: faVault,
    },
    {
        value: "safety-card",
        key: "filter.amenities.safety-card",
        icon: faIdCard,
    },
    {
        value: "sauna",
        key: "filter.amenities.sauna",
        icon: faHeat,
    },
    {
        value: "security-guards",
        key: "filter.amenities.security-guards",
        icon: faUserPolice,
    },
    {
        value: "shared-kitchen",
        key: "filter.amenities.shared-kitchen",
        icon: faOven,
    },
    {
        value: "shops",
        key: "filter.amenities.shops",
        icon: faShop,
    },
    {
        value: "small-supermarket",
        key: "filter.amenities.small-supermarket",
        icon: faCartShopping,
    },
    {
        value: "smoke-detector",
        key: "filter.amenities.smoke-detector",
        icon: faSensorFire,
    },
    {
        value: "smoking-allowed",
        key: "filter.amenities.smoking-allowed",
        icon: faSmoking,
    },
    {
        value: "spa-wellness",
        key: "filter.amenities.spa-wellness",
        icon: faSpa,
    },
    {
        value: "special-spa-package",
        key: "filter.amenities.special-spa-package",
        icon: faSpa,
    },
    {
        value: "steam-bath",
        key: "filter.amenities.steam-bath",
        icon: faBath,
    },
    {
        value: "sun-terrace",
        key: "filter.amenities.sun-terrace",
        icon: faHouseDay,
    },
    {
        value: "swimming-pool",
        key: "filter.amenities.swimming-pool",
        icon: faWaterLadder,
    },
    {
        value: "weekly-cleaning",
        key: "filter.amenities.weekly-cleaning",
        icon: faBroomWide,
    },
];

export const allRoomTypes = [
    {
        value: "hotel",
        key: "filter.hotelroom",
    },
    {
        value: "apart",
        key: "filter.apart-hotelroom",
    },
    {
        value: "hostel",
        key: "filter.hostelroom",
    },
];
